import React, { useCallback, useContext, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import app from "./base.js";
import { AuthContext } from "./Auth.js";
import axios from "axios";
import config from "./themeData/variables";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Contact from "./Contact";

import Logo from "./Logo";

let contactInfo = config.profilLogin;

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Identifiant incorrect
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Contact info={contactInfo} />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Login = ({ history }) => {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [unAuthorizedUser, setUnAuthorizedUser] = useState(false);

  const submitContent = () => {
    if (step === 0) {
      return "Se connecter";
    }

    ///Email validationEmail
    if (step === 1) {
      return <Spinner animation="border" as="span" size="sm" />;
    }
    if (step === 2) {
      return "Valider";
    }
    //Code validation
    if (step === 3) {
      return <Spinner animation="border" as="span" size="sm" />;
    }
  };

  const validateCodeAndLogin = async () => {
    setStep(3);

    const payload = { email, code };

    console.log("payload is :", payload);

    let codeCheck = await axios.post(
      "https://europe-west1-" +
        config.projectId +
        ".cloudfunctions.net/checkCode",
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      }
    );

    if (!codeCheck.data.access) {
      setUnAuthorizedUser(true);
      setStep(0);
      return;
    }

    await app
      .auth()
      .signInWithEmailAndPassword(email, "pl171657")
      .catch(function(error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage + " " + errorCode);
      });
  };

  const handleLogin = useCallback(
    async event => {
      event.preventDefault();

      if (step === 2) {
        validateCodeAndLogin();
        return;
      }

      setStep(1);
      setUnAuthorizedUser(false);

      console.log("WILL CHECK AUTH");

      let authCheck = await axios.post(
        "https://europe-west1-" +
          config.projectId +
          ".cloudfunctions.net/checkAuth",
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
          }
        }
      );

      if (!authCheck.data.access) {
        setUnAuthorizedUser(true);
        setStep(0);
        return;
      }

      setStep(2);
    },
    [history, step, email, code]
  );

  const { currentUser, initialized } = useContext(AuthContext);
  if (currentUser) {
    return <Redirect to="/" />;
  }

  const emailChangeHandler = event => {
    setEmail(event.target.value);
  };

  const codeChangeHandler = event => {
    setCode(event.target.value);
  };

  return (
    <div className="bodyLog">
      <div className="login">
        <Logo className="imgLog" baseline={true} />
        <MyVerticallyCenteredModal
          show={unAuthorizedUser}
          onHide={() => setUnAuthorizedUser(false)}
        />

        {initialized && (
          <form onSubmit={handleLogin} className="form-group">
            <input
              type="email"
              className="form-control mx-auto"
              placeholder="Votre email professionnel"
              name="email"
              onChange={emailChangeHandler}
              style={{ display: step < 2 ? "block" : "none" }}
            />
            <input
              type="text"
              className="form-control mx-auto"
              placeholder="Code reçu par email"
              name="code"
              onChange={codeChangeHandler}
              style={{ display: step >= 2 ? "block" : "none" }}
            />
            <button
              type="submit"
              className="btn btn-primary mx-auto"
              disabled={step === 1}
            >
              {submitContent()}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default withRouter(Login);
