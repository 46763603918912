import React, { useEffect, useState } from "react";
import axios from "axios";
import app from "./base.js";
import config from "./themeData/variables.json";

export const AuthContext = React.createContext();

export const AuthProvider = ({ history, children }) => {
  const initialDisplayName = () => {
    const user = app.auth().currentUser;
    if (user) {
      return user.displayName;
    }
    return "";
  };

  const [currentUser, setCurrentUser] = useState(app.auth().currentUser);
  const [initialized, setInitialized] = useState(false);
  const [displayName, setDisplayName] = useState(initialDisplayName);

  useEffect(() => {
    app.auth().onAuthStateChanged(user => {
      setCurrentUser(user);
      if (user) {
        setDisplayName(user.displayName);
      }
      setInitialized(true);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        initialized,
        displayName
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
